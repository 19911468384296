<template>
    <div>
        <div class="vx-row">
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <statistics-card-line
                    v-if="subscribersGained.analyticsData"
                    icon="UsersIcon"
                    :statistic="subscribersGained.analyticsData.subscribers | k_formatter"
                    statisticTitle="Subscribers Gained"
                    :chartData="subscribersGained.series"
                    type="area"
                />
            </div>

            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <statistics-card-line
                    v-if="revenueGenerated.analyticsData"
                    icon="DollarSignIcon"
                    :statistic="revenueGenerated.analyticsData.revenue | k_formatter"
                    statisticTitle="Revenue Generated"
                    :chartData="revenueGenerated.series"
                    color="success"
                    type="area"
                />
            </div>

            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <statistics-card-line
                    v-if="quarterlySales.analyticsData"
                    icon="ShoppingCartIcon"
                    :statistic="quarterlySales.analyticsData.sales"
                    statisticTitle="Quarterly Sales"
                    :chartData="quarterlySales.series"
                    color="danger"
                    type="area"
                />
            </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
                <router-link :to="{name: 'manage-shop'}">
                    <vx-card class="overflow-hidden h-full content-center">
                        <img class="object-center m-auto" src="../../../assets/images/icons/icon_backend_manage-campaigns.jpg" alt="manage-shop">
                        <!-- <vs-button type="border" class="w-full h-full" :to="{name: 'manage-shop'}" ></vs-button>     -->
                        <div class="truncate text-center mt-8">
                            <span>Manage Shop</span>
                        </div>
                    </vx-card>
                </router-link>
            </div>
        </div>

        <div class="vx-row">
            <!-- LINE CHART -->
            <div class="vx-col w-full md:w-2/3 mb-base">
                <vx-card title="Revenue">
                    <template slot="actions">
                        <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
                    </template>
                    <div slot="no-body" class="p-6 pb-0">
                        <div class="flex" v-if="revenueComparisonLine.analyticsData">
                            <div class="mr-6">
                                <p class="mb-1 font-semibold">This Month</p>
                                <p class="text-3xl text-success">
                                    <sup class="text-base mr-1">$</sup>
                                    {{ revenueComparisonLine.analyticsData.thisMonth.toLocaleString() }}
                                </p>
                            </div>
                            <div>
                                <p class="mb-1 font-semibold">Last Month</p>
                                <p class="text-3xl">
                                    <sup class="text-base mr-1">$</sup>
                                    {{ revenueComparisonLine.analyticsData.lastMonth.toLocaleString() }}
                                </p>
                            </div>
                        </div>
                        <vue-apex-charts
                            type="line"
                            height="266"
                            :options="analyticsData.revenueComparisonLine.chartOptions"
                            :series="revenueComparisonLine.series"
                        />
                    </div>
                </vx-card>
            </div>

            <!-- RADIAL CHART -->
            <div class="vx-col w-full md:w-1/3 mb-base">
                <vx-card title="Goal Overview">
                    <template slot="actions">
                        <feather-icon icon="HelpCircleIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
                    </template>

                    <!-- CHART -->
                    <template slot="no-body">
                        <div class="mt-10">
                            <vue-apex-charts
                                type="radialBar"
                                height="240"
                                :options="analyticsData.goalOverviewRadialBar.chartOptions"
                                :series="goalOverview.series"
                            />
                        </div>
                    </template>

                    <!-- DATA -->
                    <div class="flex justify-between text-center mt-6" slot="no-body-bottom">
                        <div
                            class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0"
                        >
                            <p class="mt-4">Completed</p>
                            <p class="mb-4 text-3xl font-semibold">786,617</p>
                        </div>
                        <div
                            class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0"
                        >
                            <p class="mt-4">In Progress</p>
                            <p class="mb-4 text-3xl font-semibold">13,561</p>
                        </div>
                    </div>
                </vx-card>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base">
                <vx-card title="Browser Statistics">
                    <div
                        v-for="(browser, index) in browserStatistics"
                        :key="browser.id"
                        :class="{'mt-4': index}"
                    >
                        <div class="flex justify-between">
                            <div class="flex flex-col">
                                <span class="mb-1">{{ browser.name }}</span>
                                <h4>{{ browser.ratio }}%</h4>
                            </div>
                            <div class="flex flex-col text-right">
                                <span class="flex -mr-1">
                                    <span class="mr-1">{{ browser.comparedResult }}</span>
                                    <feather-icon
                                        :icon=" browser.comparedResult < 0 ? 'ArrowDownIcon' : 'ArrowUpIcon'"
                                        :svgClasses="[browser.comparedResult < 0 ? 'text-danger' : 'text-success'  ,'stroke-current h-4 w-4 mb-1 mr-1']"
                                    ></feather-icon>
                                </span>
                                <span class="text-grey">{{ browser.time | time(true) }}</span>
                            </div>
                        </div>
                        <vs-progress :percent="browser.ratio"></vs-progress>
                    </div>
                </vx-card>
            </div>

            <div class="vx-col w-full md:w-2/3">
                <vx-card title="Client Retention">
                    <div class="flex">
                        <span class="flex items-center">
                            <div class="h-3 w-3 rounded-full mr-1 bg-primary"></div>
                            <span>New Clients</span>
                        </span>
                        <span class="flex items-center ml-4">
                            <div class="h-3 w-3 rounded-full mr-1 bg-danger"></div>
                            <span>Retained Clients</span>
                        </span>
                    </div>
                    <vue-apex-charts
                        type="bar"
                        height="277"
                        :options="analyticsData.clientRetentionBar.chartOptions"
                        :series="clientRetentionBar.series"
                    />
                </vx-card>
            </div>
        </div>

        <div class="vx-row">
            <!-- Sessions By Device -->
            <div class="vx-col w-full lg:w-1/3 lg:mt-0 mt-base">
                <vx-card title="Sessions By Device">
                    <!-- SLOT = ACTIONS -->
                    <template slot="actions">
                        <change-time-duration-dropdown />
                    </template>

                    <div slot="no-body">
                        <vue-apex-charts
                            class="mt-6 mb-8"
                            type="donut"
                            height="325"
                            :options="analyticsData.sessionsByDeviceDonut.chartOptions"
                            :series="sessionsData.series"
                        />
                    </div>

                    <ul class="mt-6">
                        <li
                            v-for="deviceData in sessionsData.analyticsData"
                            :key="deviceData.device"
                            class="flex mb-3"
                        >
                            <feather-icon
                                :icon="deviceData.icon"
                                :svgClasses="[`h-5 w-5 stroke-current text-${deviceData.color}`]"
                            ></feather-icon>
                            <span class="ml-2 inline-block font-semibold">{{ deviceData.device }}</span>
                            <span class="mx-2">-</span>
                            <span class="mr-4">{{ deviceData.sessionsPercentage }}%</span>
                            <div class="ml-auto flex -mr-1">
                                <span class="mr-1">{{ deviceData.comparedResultPercentage }}%</span>
                                <feather-icon
                                    :icon=" deviceData.comparedResultPercentage < 0 ? 'ArrowDownIcon' : 'ArrowUpIcon'"
                                    :svgClasses="[deviceData.comparedResultPercentage < 0 ? 'text-danger' : 'text-success'  ,'stroke-current h-4 w-4 mb-1 mr-1']"
                                ></feather-icon>
                            </div>
                        </li>
                    </ul>
                </vx-card>
            </div>

            <!-- CUSTOMERS CHART -->
            <div class="vx-col w-full lg:w-1/3 lg:mt-0 mt-base">
                <vx-card title="Customers">
                    <!-- SLOT = ACTIONS -->
                    <template slot="actions">
                        <change-time-duration-dropdown />
                    </template>

                    <div slot="no-body">
                        <!-- CHART -->
                        <vue-apex-charts
                            type="pie"
                            height="345"
                            class="mt-10 mb-10"
                            :options="analyticsData.customersPie.chartOptions"
                            :series="customersData.series"
                        />

                        <!-- CHART DATA -->
                        <ul class="mb-1">
                            <li
                                v-for="customerData in customersData.analyticsData"
                                :key="customerData.customerType"
                                class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
                            >
                                <span class="flex items-center">
                                    <span
                                        class="inline-block h-3 w-3 rounded-full mr-2"
                                        :class="`bg-${customerData.color}`"
                                    ></span>
                                    <span class="font-semibold">{{ customerData.customerType }}</span>
                                </span>
                                <span>{{ customerData.counts }}</span>
                            </li>
                        </ul>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/modules/Template/Components/statistics-cards/StatisticsCardLine.vue";
import analyticsData from "./ui-elements/card/analyticsData.js";
import ChangeTimeDurationDropdown from "@/modules/Template/Components/ChangeTimeDurationDropdown.vue";

export default {
    components: {
        VueApexCharts,
        StatisticsCardLine,
        VuePerfectScrollbar,
        ChangeTimeDurationDropdown
    },
    data() {
        return {
            subscribersGained: {
                series: [
                    {
                        name: "Subscribers",
                        data: [28, 40, 36, 52, 38, 60, 55]
                    }
                ],
                analyticsData: {
                    subscribers: 92600
                }
            },
            revenueGenerated: {
                series: [
                    {
                        name: "Revenue",
                        data: [350, 275, 400, 300, 350, 300, 450]
                    }
                ],
                analyticsData: {
                    revenue: 97500
                }
            },
            quarterlySales: {
                series: [
                    {
                        name: "Sales",
                        data: [10, 15, 7, 12, 3, 16]
                    }
                ],
                analyticsData: {
                    sales: "36%"
                }
            },
            ordersRecevied: {
                series: [
                    {
                        name: "Orders",
                        data: [10, 15, 8, 15, 7, 12, 8]
                    }
                ],
                analyticsData: {
                    orders: 97500
                }
            },

            revenueComparisonLine: {
                analyticsData: {
                    thisMonth: 86589,
                    lastMonth: 73683
                },
                series: [
                    {
                        name: "This Month",
                        data: [
                            45000,
                            47000,
                            44800,
                            47500,
                            45500,
                            48000,
                            46500,
                            48600
                        ]
                    },
                    {
                        name: "Last Month",
                        data: [
                            46000,
                            48000,
                            45500,
                            46600,
                            44500,
                            46500,
                            45000,
                            47000
                        ]
                    }
                ]
            },
            goalOverview: {
                analyticsData: {
                    completed: 786617,
                    inProgress: 13561
                },
                series: [83]
            },

            browserStatistics: [
                {
                    id: 1,
                    name: "Google Chrome",
                    ratio: 73,
                    time: "Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)",
                    comparedResult: "800"
                },
                {
                    id: 3,
                    name: "Opera",
                    ratio: 8,
                    time: "Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)",
                    comparedResult: "-200"
                },
                {
                    id: 2,
                    name: "Firefox",
                    ratio: 19,
                    time: "Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)",
                    comparedResult: "100"
                },
                {
                    id: 4,
                    name: "Internet Explorer",
                    ratio: 27,
                    time: "Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)",
                    comparedResult: "-450"
                }
            ],
            clientRetentionBar: {
                series: [
                    {
                        name: "New Clients",
                        data: [
                            175,
                            125,
                            225,
                            175,
                            160,
                            189,
                            206,
                            134,
                            159,
                            216,
                            148,
                            123
                        ]
                    },
                    {
                        name: "Retained Clients",
                        data: [
                            -144,
                            -155,
                            -141,
                            -167,
                            -122,
                            -143,
                            -158,
                            -107,
                            -126,
                            -131,
                            -140,
                            -137
                        ]
                    }
                ]
            },

            sessionsData: {
                analyticsData: [
                    {
                        device: "Dekstop",
                        icon: "MonitorIcon",
                        color: "primary",
                        sessionsPercentage: 58.6,
                        comparedResultPercentage: 2
                    },
                    {
                        device: "Mobile",
                        icon: "SmartphoneIcon",
                        color: "warning",
                        sessionsPercentage: 34.9,
                        comparedResultPercentage: 8
                    },
                    {
                        device: "Tablet",
                        icon: "TabletIcon",
                        color: "danger",
                        sessionsPercentage: 6.5,
                        comparedResultPercentage: -5
                    }
                ],
                series: [58.6, 34.9, 6.5]
            },
            chatMsgInput: "",
            customersData: {
                analyticsData: [
                    {
                        customerType: "New",
                        counts: 890,
                        color: "primary"
                    },
                    {
                        customerType: "Returning",
                        counts: 258,
                        color: "warning"
                    },
                    {
                        customerType: "Referrals ",
                        counts: 149,
                        color: "danger"
                    }
                ],
                series: [690, 258, 149]
            },

            analyticsData,
            settings: {
                // perfectscrollbar settings
                maxScrollbarLength: 60,
                wheelSpeed: 0.6
            }
        };
    },
    computed: {
        scrollbarTag() {
            return this.$store.getters.scrollbarTag;
        }
    },
    mounted() {}
};
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}
</style>
