var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
        },
        [
          _vm.subscribersGained.analyticsData
            ? _c("statistics-card-line", {
                attrs: {
                  icon: "UsersIcon",
                  statistic: _vm._f("k_formatter")(
                    _vm.subscribersGained.analyticsData.subscribers
                  ),
                  statisticTitle: "Subscribers Gained",
                  chartData: _vm.subscribersGained.series,
                  type: "area"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
        },
        [
          _vm.revenueGenerated.analyticsData
            ? _c("statistics-card-line", {
                attrs: {
                  icon: "DollarSignIcon",
                  statistic: _vm._f("k_formatter")(
                    _vm.revenueGenerated.analyticsData.revenue
                  ),
                  statisticTitle: "Revenue Generated",
                  chartData: _vm.revenueGenerated.series,
                  color: "success",
                  type: "area"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
        },
        [
          _vm.quarterlySales.analyticsData
            ? _c("statistics-card-line", {
                attrs: {
                  icon: "ShoppingCartIcon",
                  statistic: _vm.quarterlySales.analyticsData.sales,
                  statisticTitle: "Quarterly Sales",
                  chartData: _vm.quarterlySales.series,
                  color: "danger",
                  type: "area"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base"
        },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "manage-shop" } } },
            [
              _c(
                "vx-card",
                { staticClass: "overflow-hidden h-full content-center" },
                [
                  _c("img", {
                    staticClass: "object-center m-auto",
                    attrs: {
                      src: require("../../../assets/images/icons/icon_backend_manage-campaigns.jpg"),
                      alt: "manage-shop"
                    }
                  }),
                  _c("div", { staticClass: "truncate text-center mt-8" }, [
                    _c("span", [_vm._v("Manage Shop")])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Revenue" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "SettingsIcon",
                      svgClasses: "w-6 h-6 text-grey"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "p-6 pb-0",
                  attrs: { slot: "no-body" },
                  slot: "no-body"
                },
                [
                  _vm.revenueComparisonLine.analyticsData
                    ? _c("div", { staticClass: "flex" }, [
                        _c("div", { staticClass: "mr-6" }, [
                          _c("p", { staticClass: "mb-1 font-semibold" }, [
                            _vm._v("This Month")
                          ]),
                          _c("p", { staticClass: "text-3xl text-success" }, [
                            _c("sup", { staticClass: "text-base mr-1" }, [
                              _vm._v("$")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.revenueComparisonLine.analyticsData.thisMonth.toLocaleString()
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("div", [
                          _c("p", { staticClass: "mb-1 font-semibold" }, [
                            _vm._v("Last Month")
                          ]),
                          _c("p", { staticClass: "text-3xl" }, [
                            _c("sup", { staticClass: "text-base mr-1" }, [
                              _vm._v("$")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.revenueComparisonLine.analyticsData.lastMonth.toLocaleString()
                                ) +
                                " "
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _c("vue-apex-charts", {
                    attrs: {
                      type: "line",
                      height: "266",
                      options:
                        _vm.analyticsData.revenueComparisonLine.chartOptions,
                      series: _vm.revenueComparisonLine.series
                    }
                  })
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Goal Overview" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "HelpCircleIcon",
                      svgClasses: "w-6 h-6 text-grey"
                    }
                  })
                ],
                1
              ),
              _c("template", { slot: "no-body" }, [
                _c(
                  "div",
                  { staticClass: "mt-10" },
                  [
                    _c("vue-apex-charts", {
                      attrs: {
                        type: "radialBar",
                        height: "240",
                        options:
                          _vm.analyticsData.goalOverviewRadialBar.chartOptions,
                        series: _vm.goalOverview.series
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "flex justify-between text-center mt-6",
                  attrs: { slot: "no-body-bottom" },
                  slot: "no-body-bottom"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0"
                    },
                    [
                      _c("p", { staticClass: "mt-4" }, [_vm._v("Completed")]),
                      _c("p", { staticClass: "mb-4 text-3xl font-semibold" }, [
                        _vm._v("786,617")
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0"
                    },
                    [
                      _c("p", { staticClass: "mt-4" }, [_vm._v("In Progress")]),
                      _c("p", { staticClass: "mb-4 text-3xl font-semibold" }, [
                        _vm._v("13,561")
                      ])
                    ]
                  )
                ]
              )
            ],
            2
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Browser Statistics" } },
            _vm._l(_vm.browserStatistics, function(browser, index) {
              return _c(
                "div",
                { key: browser.id, class: { "mt-4": index } },
                [
                  _c("div", { staticClass: "flex justify-between" }, [
                    _c("div", { staticClass: "flex flex-col" }, [
                      _c("span", { staticClass: "mb-1" }, [
                        _vm._v(_vm._s(browser.name))
                      ]),
                      _c("h4", [_vm._v(_vm._s(browser.ratio) + "%")])
                    ]),
                    _c("div", { staticClass: "flex flex-col text-right" }, [
                      _c(
                        "span",
                        { staticClass: "flex -mr-1" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(_vm._s(browser.comparedResult))
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon:
                                browser.comparedResult < 0
                                  ? "ArrowDownIcon"
                                  : "ArrowUpIcon",
                              svgClasses: [
                                browser.comparedResult < 0
                                  ? "text-danger"
                                  : "text-success",
                                "stroke-current h-4 w-4 mb-1 mr-1"
                              ]
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "text-grey" }, [
                        _vm._v(_vm._s(_vm._f("time")(browser.time, true)))
                      ])
                    ])
                  ]),
                  _c("vs-progress", { attrs: { percent: browser.ratio } })
                ],
                1
              )
            }),
            0
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full md:w-2/3" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Client Retention" } },
            [
              _c("div", { staticClass: "flex" }, [
                _c("span", { staticClass: "flex items-center" }, [
                  _c("div", {
                    staticClass: "h-3 w-3 rounded-full mr-1 bg-primary"
                  }),
                  _c("span", [_vm._v("New Clients")])
                ]),
                _c("span", { staticClass: "flex items-center ml-4" }, [
                  _c("div", {
                    staticClass: "h-3 w-3 rounded-full mr-1 bg-danger"
                  }),
                  _c("span", [_vm._v("Retained Clients")])
                ])
              ]),
              _c("vue-apex-charts", {
                attrs: {
                  type: "bar",
                  height: "277",
                  options: _vm.analyticsData.clientRetentionBar.chartOptions,
                  series: _vm.clientRetentionBar.series
                }
              })
            ],
            1
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 lg:mt-0 mt-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Sessions By Device" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [_c("change-time-duration-dropdown")],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-body" }, slot: "no-body" },
                [
                  _c("vue-apex-charts", {
                    staticClass: "mt-6 mb-8",
                    attrs: {
                      type: "donut",
                      height: "325",
                      options:
                        _vm.analyticsData.sessionsByDeviceDonut.chartOptions,
                      series: _vm.sessionsData.series
                    }
                  })
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "mt-6" },
                _vm._l(_vm.sessionsData.analyticsData, function(deviceData) {
                  return _c(
                    "li",
                    { key: deviceData.device, staticClass: "flex mb-3" },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: deviceData.icon,
                          svgClasses: [
                            "h-5 w-5 stroke-current text-" + deviceData.color
                          ]
                        }
                      }),
                      _c(
                        "span",
                        { staticClass: "ml-2 inline-block font-semibold" },
                        [_vm._v(_vm._s(deviceData.device))]
                      ),
                      _c("span", { staticClass: "mx-2" }, [_vm._v("-")]),
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(_vm._s(deviceData.sessionsPercentage) + "%")
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-auto flex -mr-1" },
                        [
                          _c("span", { staticClass: "mr-1" }, [
                            _vm._v(
                              _vm._s(deviceData.comparedResultPercentage) + "%"
                            )
                          ]),
                          _c("feather-icon", {
                            attrs: {
                              icon:
                                deviceData.comparedResultPercentage < 0
                                  ? "ArrowDownIcon"
                                  : "ArrowUpIcon",
                              svgClasses: [
                                deviceData.comparedResultPercentage < 0
                                  ? "text-danger"
                                  : "text-success",
                                "stroke-current h-4 w-4 mb-1 mr-1"
                              ]
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-full lg:w-1/3 lg:mt-0 mt-base" },
        [
          _c(
            "vx-card",
            { attrs: { title: "Customers" } },
            [
              _c(
                "template",
                { slot: "actions" },
                [_c("change-time-duration-dropdown")],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "no-body" }, slot: "no-body" },
                [
                  _c("vue-apex-charts", {
                    staticClass: "mt-10 mb-10",
                    attrs: {
                      type: "pie",
                      height: "345",
                      options: _vm.analyticsData.customersPie.chartOptions,
                      series: _vm.customersData.series
                    }
                  }),
                  _c(
                    "ul",
                    { staticClass: "mb-1" },
                    _vm._l(_vm.customersData.analyticsData, function(
                      customerData
                    ) {
                      return _c(
                        "li",
                        {
                          key: customerData.customerType,
                          staticClass:
                            "flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0"
                        },
                        [
                          _c("span", { staticClass: "flex items-center" }, [
                            _c("span", {
                              staticClass:
                                "inline-block h-3 w-3 rounded-full mr-2",
                              class: "bg-" + customerData.color
                            }),
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v(_vm._s(customerData.customerType))
                            ])
                          ]),
                          _c("span", [_vm._v(_vm._s(customerData.counts))])
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }