<template>
  <div v-if="!isLoading" id="shop-dashboard">
    <div class="vx-row">
            <div class="vx-col w-full mb-base">
                <div class="vx-col float-left">
                    <h1>{{$t('Dashboard')}}</h1>
                </div>
            </div>
        </div>
    <ShopDashboard />
  </div>
</template>

<script>
import ShopDashboard from '../Components/ShopDashboard'

export default {
  components: {
    ShopDashboard
  },
  props: {
    isLoading: false
  },
  name: 'ShopsDashboard',
}
</script>

<style>

</style>
