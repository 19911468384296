var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c(
        "div",
        { attrs: { id: "shop-dashboard" } },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full mb-base" }, [
              _c("div", { staticClass: "vx-col float-left" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("Dashboard")))])
              ])
            ])
          ]),
          _c("ShopDashboard")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }